import React, { useEffect, useRef } from "react";
import css from "../styles/Card.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const AmazingLoader = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className={css.amazingLoader}>
				<div className={css.containerWrapper}>
					<div className={css.container}>
						<div className={css.loader}></div>
					</div>
					<div
						className={classNames(
							css.analyzing,
							i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
						)}
					>
						Analyzing
					</div>
				</div>
			</div>
		</>
	);
};

export default AmazingLoader;
