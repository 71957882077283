import React from "react";
import css from "../styles/App.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Popup = ({ popup, setPopup }) => {
	const { t, i18n } = useTranslation();
	return (
		<div className={css.popupContainer}>
			<div className={css.popupContent}>
				{!!popup.close && (
					<div
						className={css.popupClose}
						onClick={() => setPopup({ active: false })}
					/>
				)}
				<div
					className={classNames(
						css.popupTitle,
						i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium",
					)}
				>
					{popup.title}
				</div>
				<p className={classNames(css.popupText)}>{popup.text}</p>
				{popup.actions}
				{popup.subActions}
			</div>
		</div>
	);
};

export default Popup;
