import React, { useEffect, useRef } from "react";
import css from "../styles/App.module.scss";
import classNames from "classnames";

const Video = ({ data, setVideo }) => {
	return (
		<>
			{!!data.show && (
				<div className={classNames(css.videoPopup)}>
					<div className={css.closeContainer}>
						<div
							className={css.videoClose}
							onClick={() => {
								setVideo({ show: false, video: "" });
							}}
						/>
					</div>
					<video
						className={css.video}
						poster="prevVideo.png"
						autoPlay
						loop
						playsInline
						preload="auto"
						id={data.video}
					>
						<source
							src={`/${data.video}`}
							type="video/mp4"
						/>
						Your browser does not support the video tag.
					</video>
				</div>
			)}
		</>
	);
};

export default Video;
