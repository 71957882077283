/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable no-fallthrough */
import React, { useEffect, useState } from "react";
import css from "../styles/App.module.scss";
import classNames from "classnames";
import { toast } from "react-toastify";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import axios from "axios";
import { useTranslation } from "react-i18next";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = phone => {
	try {
		return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
	} catch (error) {
		return false;
	}
};

const Login = ({ lang, setMode, userData, setUserData }) => {
	const { t, i18n } = useTranslation();
	const [data, setData] = useState({});
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [isValidPhone, setIsValidPhone] = useState(true);

	const handleChange = e => {
		const { name, value } = e.target;
		const newData = { ...data };

		newData[name] = value;
		setData(newData);
		setIsValidEmail(true);
	};

	const handleSubmit = async () => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		setIsValidEmail(emailPattern.test(data.email));

		if (!emailPattern.test(data.email)) {
			const timeout1 = setTimeout(() => {
				setIsValidEmail(true);
				return () => clearTimeout(timeout1);
			}, 300);
			return;
		}

		const isValid = isPhoneValid(data?.phone);
		setIsValidPhone(isValid);

		if (!isValid) {
			const timeout2 = setTimeout(() => {
				setIsValidPhone(true);
				return () => clearTimeout(timeout2);
			}, 300);
			return;
		}

		try {
			const newData = {
				phone: data.phone.replace(/[^0-9]+/g, ""),
				email: data.email,
				platform_id: userData.platform_id,
			};
			const response = await axios.post(
				`${process.env.REACT_APP_HOST}/api/AviatorSoft/Client/registration`,
				newData,
			);
			if (!!response?.data?.success) {
				setUserData(response?.data?.data);
				setMode(2);
			} else {
				errorMessage(response?.data?.message);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (!isValidPhone) {
			errorMessage(t("login.message.phone"));
		}
		if (!isValidEmail) {
			errorMessage(t("login.message.email"));
		}
	}, [isValidEmail, isValidPhone]);

	const errorMessage = message => {
		toast.error(message, {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "dark",
		});
	};

	const message = message => {
		toast(message, {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "dark",
		});
	};

	return (
		<>
			<label
				className={css.formContainer}
				htmlFor=""
			>
				<span
					className={classNames(
						css.loginTitle,
						i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium",
          )}
          
				>
					{t("login.title")}
				</span>

				<PhoneInput
					defaultCountry="in"
					value={data?.phone || ""}
					name="phone"
					onChange={phone => {
						const newData = { ...data };
						newData.phone = phone;
						setData(newData);
					}}
				/>
				<input
					className={classNames(css.input, "enMedium")}
					type="text"
					placeholder="EMAIL"
					name="email"
					onChange={handleChange}
				/>
			</label>

			<button
        className={classNames(css.button, i18n.resolvedLanguage === "en" ? "enBold" : "hinBold")}
        style={i18n.resolvedLanguage === "en" ? {} : {fontSize: "23px"}}
				onClick={handleSubmit}
			>
				{t("login.button")}
			</button>
		</>
	);
};

export default Login;
