/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import css from "../styles/Card.module.scss";
import styles from "../styles/App.module.scss";
import classNames from "classnames";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Card3 = ({ setPopup, signalData, data, setVideo, setStep, getData }) => {
	const { t, i18n } = useTranslation();
	const [time, setTime] = useState(18.05);
	const [anim, setAnim] = useState(1);

	useEffect(() => {
		let intervalId;
		const startTime = Date.now();
		intervalId = setInterval(() => {
			const elapsedTime = (Date.now() - startTime) / 1000;
			if (elapsedTime >= time) {
				setTime(0);
				openPopup1();
				clearInterval(intervalId);
			} else {
				setTime(time - elapsedTime);
			}
		}, 10);

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (anim === 1) {
				setAnim(2);
			} else if (anim === 2) {
				setAnim(3);
			} else if (anim === 3) {
				setAnim(4);
			} else if (anim === 4) {
				setAnim(1);
			}
		}, 50);

		return () => clearTimeout(timeout);
	}, [anim]);

	const formatTime = () => {
		const seconds = Math.floor(time);
		const milliseconds = Math.floor((time - seconds) * 100);

		return (
			<>
				<span className={css.firstSecond}>{seconds < 10 && seconds !== 0 ? "0" : ""}</span>
				<span className={css.firstSecond}>{String(seconds).split("")[0]}</span>
				<span className={css.lastSecond}>{String(seconds).split("")[1]}</span>
				<span className={css.dot}>.</span>
				<span className={css.firstMillisecond}>{milliseconds < 10 ? "0" : ""}</span>
				<span className={css.firstMillisecond}>{String(milliseconds).split("")[0]}</span>
				<span className={css.lastMillisecond}>{String(milliseconds).split("")[1]}</span>
			</>
		);
	};

	const updateSignal = async status => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_HOST}/api/AviatorSoft/Client/updateSignal`,
				{ id: signalData?.id, status },
			);
			if (!!response?.data?.success) {
				if (!!status) {
					openPopup2();
					setStep(0);
					getData();
				} else {
					openPopup3();
					setStep(0);
					getData();
				}
			}
		} catch (error) {
			console.log("Error", error);
		}
	};

	const openPopup1 = () => {
		setPopup({
			close: false,
			active: true,
			text: t("card3_popup1.text"),
			title: <>{t("card3_popup1.title")}</>,
			actions: (
				<>
					<div className={classNames("enBold", css.coef, css._2)}>
						<span>x{signalData?.coefficient}</span>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: "10px",
							width: "100%",
						}}
					>
						<button
							className={classNames(
								styles.button,
								styles._small,
								styles._blue,
								styles._green,
								i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
							)}
							onClick={() => updateSignal(1)}
						>
							{t("card3_popup1.buttons.b1")}
						</button>
						<button
							className={classNames(
								styles.button,
								styles._small,
								styles._blue,
								styles._orange,
								i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
								styles._gray,
							)}
							onClick={() => updateSignal(0)}
						>
							{t("card3_popup1.buttons.b2")}
						</button>
					</div>
				</>
			),
		});
	};

	const openPopup2 = () => {
		setPopup({
			close: true,
			active: true,
			text: t("card3_popup3.text"),
			title: <>{t("card3_popup3.title")}</>,
			actions: (
				<>
					<div className={classNames("enBold", css.coef, css._1)}>
						<span>x{signalData?.coefficient}</span>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: "10px",
							width: "100%",
						}}
					>
						<button
							className={classNames(
								styles.button,
								styles._small,
								i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
							)}
							onClick={() =>
								window.open(`https://thlbots.com/?type=deposit&lead_id=${data.lead_id}`, "_blank")
							}
						>
							{t("card3_popup3.buttons.b1")}
						</button>
						{!!data.username && (
							<button
								className={classNames(
									styles.button,
									styles._small,
									styles._blue,
									i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
									styles._gray,
								)}
								onClick={() => window.open(`https://t.me/${data.username}`, "_blank")}
							>
								{t("card3_popup3.buttons.b2")}
							</button>
						)}
					</div>
				</>
			),
			subActions: (
				<>
					<div
						className={styles.videoBoard}
						onClick={() => setVideo({ show: true, video: "Tutor_Deposit.mp4" })}
					>
						<span
							className={classNames(
								i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
								styles.text,
							)}
						>
							{t("makeDeposit")}
						</span>
						<div className={styles.buttonPlay}>
							<div className={styles.icon} />
						</div>
					</div>
				</>
			),
		});
	};

	const openPopup3 = () => {
		setPopup({
			close: true,
			active: true,
			text: t("card3_popup2.text"),
			title: <>{t("card3_popup2.title")}</>,
			actions: (
				<>
					<div className={classNames("enBold", css.coef, css._3)}>
						<span>x{signalData?.coefficient}</span>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: "10px",
							width: "100%",
						}}
					>
						<button
							className={classNames(
								styles.button,
								styles._small,
								i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
							)}
							onClick={() =>
								window.open(`https://thlbots.com/?type=deposit&lead_id=${data.lead_id}`, "_blank")
							}
						>
							{t("card3_popup3.buttons.b1")}
						</button>
						{!!data.username && (
							<button
								className={classNames(
									styles.button,
									styles._small,
									styles._blue,
									i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
									styles._gray,
								)}
								onClick={() => window.open(`https://t.me/${data.username}`, "_blank")}
							>
								{t("card3_popup3.buttons.b2")}
							</button>
						)}
					</div>
				</>
			),
			subActions: (
				<>
					<div
						className={styles.videoBoard}
						onClick={() => setVideo({ show: true, video: "Tutor_Deposit.mp4" })}
					>
						<span
							className={classNames(
								i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
								styles.text,
							)}
						>
							{t("makeDeposit")}
						</span>
						<div className={styles.buttonPlay}>
							<div className={styles.icon} />
						</div>
					</div>
				</>
			),
		});
	};

	return (
		<div className={css.card}>
			<div
				className={css.cardBody}
				style={{ padding: "40px 10px" }}
			>
				<div className={css.timerContainer}>
					<div className={css.tic} />
					<span className={classNames(css.enMedium, css.timer)}>{formatTime()}</span>
					<div className={css.planeContainer}>
						<div
							className={classNames(
								css.planeAlternative,
								anim === 1 && css._1,
								anim === 2 && css._2,
								anim === 3 && css._3,
								anim === 4 && css._4,
							)}
						/>
					</div>
				</div>

				<div className={css.info}>
					<div className={classNames("enBold", css.coef, css._2)}>
						<span>x{signalData?.coefficient}</span>
					</div>
				</div>
				<div className={css.info2}>
					<div className={classNames(css.pro, "enMedium")}>
						<span>{Number(signalData?.percent).toFixed(2)}%</span>
					</div>
					<p className={classNames(i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium")}>
						{t("cardPlaner.text")} <span>{Number(signalData?.percent).toFixed(2)}%</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Card3;
