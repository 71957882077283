/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import css from "../styles/App.module.scss";
import classNames from "classnames";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";

const SendId = ({ lang, setMode, setPopup, setData, setVideo }) => {
	const { t, i18n } = useTranslation();
	const [id, setId] = useState("");

	const handleChange = e => {
		const { name, value } = e.target;
		let newValue = value.replace(/\D/g, "");
		setId(newValue);
	};

	const handleSubmit = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_HOST}/api/AviatorSoft/Client/login?platform_id=${id}`,
			);

			const { data, success } = response.data;

			if (!success) {
				return errorMessage("Error, check the data is correct");
			} else {
				if (!!data.is_login) {
					setData(data);
					return setMode(2);
				}

				if (!data.is_search) {
					return setPopup({
						close: true,
						active: true,
						text: t("idPopup.v1.text"),
						title: t("idPopup.v1.title"),
						actions: (
							<button
								className={classNames(
									css.button,
									i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
									css._small,
								)}
								style={{ maxWidth: "180px" }}
								onClick={() => setPopup({ active: false })}
							>
								{t("idPopup.v1.buttons.b1")}
							</button>
						),
						subActions: (
							<>
								<button
									className={classNames(css.showVideo)}
									style={{ margin: "0px" }}
									onClick={() => {
										setVideo({ show: true, video: "Tutor_ID.mp4" });
									}}
								>
									{t("idPopup.v1.buttons.b2")}
								</button>
							</>
						),
					});
				}

				if (!data.is_amount) {
					return setPopup({
						close: true,
						active: true,
						text: (
							<>
								{t("idPopup.v2.text.s1")}{" "}
								<span className={"enBold"}>
									{Number(data.balance_required || 0).toFixed(2)}$ (
									{Number(data.balance_required * 100).toFixed(2)} INR)
								</span>
								{t("idPopup.v2.text.s2")}{" "}
								<span className={"enBold"}>
									{data.insufficient_deposit <= 0
										? 0
										: Number(data.insufficient_deposit).toFixed(2)}
									$ (
									{data.insufficient_deposit <= 0
										? 0
										: Number(data.insufficient_deposit * 83.5).toFixed(2)}{" "}
									INR)
								</span>
							</>
						),

						title: t("idPopup.v2.title"),
						actions: (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: "10px",
									width: "100%",
								}}
							>
								<button
									className={classNames(
										css.button,
										css._small,
										i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
									)}
									onClick={() =>
										window.open(
											`https://thlbots.com/?type=deposit&lead_id=${data.lead_id}`,
											"_blank",
										)
									}
								>
									{t("idPopup.v2.buttons.b1")}
								</button>
								{!!data.username && (
									<button
										className={classNames(
											css.button,
											css._small,
											css._blue,
											i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
											css._gray,
										)}
										onClick={() => window.open(`https://t.me/${data.username}`, "_blank")}
									>
										{t("idPopup.v2.buttons.b2")}
									</button>
								)}
							</div>
						),
						subActions: (
							<>
								<button
									className={classNames(css.showVideo)}
									style={{ margin: "0px" }}
								>
									{t("idPopup.v2.buttons.b3")}
								</button>
								<div
									className={css.videoBoard}
									onClick={() => setVideo({ show: true, video: "Tutor_Deposit.mp4" })}
								>
									<span
										className={classNames(
											i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
											css.text,
										)}
									>
										{t("makeDeposit")}
									</span>
									<div className={css.buttonPlay}>
										<div className={css.icon} />
									</div>
								</div>
							</>
						),
					});
				}

				if (!!data.is_amount && !data.is_registration) {
					setData(response.data.data);
					return setMode(1);
				} else {
					return errorMessage("Error, check the data is correct");
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	const errorMessage = message => {
		toast.error(message, {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "dark",
		});
	};

	return (
		<>
			<label
				className={css.formContainer}
				htmlFor=""
			>
				<span
					className={classNames(
						css.loginTitle,
						i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium",
          )}
          style={i18n.resolvedLanguage === "en" ? {} : {fontSize: "19px"}}
				>
					{t("id.title")}
				</span>

				<input
					className={classNames(css.input, "enMedium")}
					type="text"
					placeholder="ID"
					name="id"
					value={id}
					onChange={handleChange}
				/>
			</label>

			<button
        className={classNames(css.button, i18n.resolvedLanguage === "en" ? "enBold" : "hinBold")}
        style={i18n.resolvedLanguage === "en" ? {} : {fontSize: "23px"}}
				onClick={handleSubmit}
			>
				{t("id.button")}
			</button>
			<button
				className={classNames(css.showVideo)}
				onClick={() => setVideo({ show: true, video: "Tutor_ID.mp4" })}
			>
				{t("id.video")}
			</button>
			<div
        className={classNames(css.more, i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium")}
        style={i18n.resolvedLanguage === "en" ? {} : {fontSize: "16px"}}
			>
				{t("arrow")}
			</div>
			<div className={css.arrow} />
		</>
	);
};

export default SendId;
