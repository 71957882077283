/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import css from "./styles/App.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Login from "./components/Login";
import SendId from "./components/SendId";
import Popup from "./components/Popup";
import CardContainer from "./components/CardContainer";
import Card from "./components/Card";
import Video from "./components/Video";
import Revievs from "./components/Revievs";

function App() {
	const { t, i18n } = useTranslation();
	const [mode, setMode] = useState(0);

	const [data, setData] = useState({});

	const [lang, setLang] = useState("en");
	const [popup, setPopup] = useState({ active: false });
	const [video, setVideo] = useState({ show: false });

	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	useEffect(() => {
		const storeId = JSON.parse(localStorage.getItem("aviatorSoft"));
		if (!!storeId) {
			setData(storeId);
			setMode(2);
		}
	}, []);

	return (
		<>
			<div
				className={css.container}
				style={{ display: "flex", flexDirection: "column" }}
			>
				<CardContainer
					lang={lang}
					setLang={setLang}
				>
					{(mode === 0 || mode === 1) && (
						<>
							<div className={css.title} />
							{mode === 0 && (
								<>
									<SendId
										lang={lang}
										setMode={setMode}
										setPopup={setPopup}
										setData={setData}
										setVideo={setVideo}
									/>
								</>
							)}
							{mode === 1 && (
								<Login
									lang={lang}
									setMode={setMode}
									userData={data}
									setUserData={setData}
									setVideo={setVideo}
								/>
							)}
						</>
					)}
					{mode === 2 && (
						<>
							<Card
								lang={lang}
								data={data}
								setPopup={setPopup}
								setVideo={setVideo}
							/>
						</>
					)}
				</CardContainer>
				{!!popup.active && (
					<Popup
						popup={popup}
						setPopup={setPopup}
					/>
				)}
			</div>
			{mode === 0 && <Revievs />}
			<Video
				data={video}
				setVideo={setVideo}
			/>
			<ToastContainer
				position="top-right"
				autoClose={2000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
		</>
	);
}

export default App;
