import React from "react";
import css from "../styles/Card.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const CardContainer = ({ children, lang, setLang }) => {
  const { t, i18n } = useTranslation();
	return (
		<div className={css.container}>
			<label
				className={classNames(css.checkbox, "enMedium")}
				onClick={() => {
					lang === "en" ? setLang("hi") : setLang("en");
				}}
			>
				<div
					className={classNames(
						css.switcher,
						lang === "en" && css._pos1,
						lang === "hi" && css._pos2,
					)}
				/>
				<span className={classNames(css.lang, css._left, lang === "en" && css._active)}>eng</span>
				<span className={classNames(css.lang, css._right, lang === "hi" && css._active)}>hi</span>
			</label>
			{children}
		</div>
	);
};

export default CardContainer;
