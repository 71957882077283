import React, { useEffect, useState } from "react";
import css from "../styles/Revievs.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { EffectFlip, Pagination, Navigation, Controller } from "swiper/modules";

const Revievs = () => {
	const { t, i18n } = useTranslation();

	const stopVideo = e => {
		// Зупиняє всі відео
		document.querySelectorAll("video").forEach(video => {
			if (`videoSlide_${e.activeIndex}` !== video.id) {
				video.removeAttribute("controls");
			} else {
				video.setAttribute("controls", true);
			}
			video.pause();
			video.currentTime = 0;
			video.load();
		});
	};

	return (
		<div className={css.revievs}>
			<h3
				className={classNames(css.title, i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium")}
			>
				{t("revievs.title_1")}
			</h3>
			<div className={css.slider}>
				<Swiper
					effect={"flip"}
					grabCursor={true}
					pagination={true}
					navigation={true}
					modules={[EffectFlip, Pagination, Navigation]}
					className="mySwiper"
				>
					<SwiperSlide>
						<div className={css.slideContainer}>
							<img
								src="slider_1/1.png"
								alt=""
							/>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className={css.slideContainer}>
							<img
								src="slider_1/2.png"
								alt=""
							/>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className={css.slideContainer}>
							<img
								src="slider_1/3.png"
								alt=""
							/>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className={css.slideContainer}>
							<img
								src="slider_1/4.png"
								alt=""
							/>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>
			<div className={css.line} />
			<h3
				className={classNames(css.title, i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium")}
			>
				{t("revievs.title_2")}
			</h3>
			<div className={css.steps}>
				<div className={classNames(css.step, "enBold")}>1</div>
				<div
					className={classNames(
						css.stepText,
						i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
					)}
				>
					{t("revievs.step_1")}
				</div>
				<div className={classNames(css.step, "enBold")}>2</div>
				<div
					className={classNames(
						css.stepText,
						i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
					)}
				>
					{t("revievs.step_2")}
				</div>
				<div className={classNames(css.step, "enBold")}>3</div>
				<div
					className={classNames(
						css.stepText,
						i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
					)}
				>
					{t("revievs.step_3")}
				</div>
			</div>
			<div className={css.line} />
			<h3
				className={classNames(css.title, i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium")}
			>
				{t("revievs.title_3")}
			</h3>
			<div className={css.slider}>
				<Swiper
					effect={"flip"}
					grabCursor={true}
					pagination={true}
					navigation={true}
					modules={[EffectFlip, Pagination, Navigation, Controller]}
					onSwiper={e => console.log(e)}
					onSlideChange={e => stopVideo(e)}
					className="mySwiper"
				>
					{["Vid11.mp4", "Vid22.mp4", "Vid33.mp4", "Vid44.mp4", "Vid55.mp4", "Vid66.mp4", "Vid77.mp4"].map(
						(video, index) => {
							return (
								<SwiperSlide key={`${video}-${index}`}>
									<div className={classNames(css.slideContainer, css._video)}>
										<video
											className={css.video}
											poster={`videos/OTZIV_${index + 1}.png`}
											// autoPlay
											// loop
											id={`videoSlide_${index}`}
											playsInline
											controls={true}
											preload="auto"
										>
											<source
												src={`videos/${video}`}
												type="video/mp4"
											/>
											Your browser does not support the video tag.
										</video>
									</div>
								</SwiperSlide>
							);
						},
					)}
				</Swiper>
			</div>
			<div className={css.line} />
			<h3
				className={classNames(css.title, i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium")}
			>
				{t("revievs.title_4")}
			</h3>
			<div className={css.slider}>
				<Swiper
					effect={"flip"}
					grabCursor={true}
					pagination={true}
					navigation={true}
					modules={[EffectFlip, Pagination, Navigation]}
					className="mySwiper"
				>
					{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((el, index) => {
						return (
							<SwiperSlide key={`${el}-${index}-el`}>
								<div className={classNames(css.slideContainer, css._largeFoto)}>
									<img
										src={`slider_2/${el}-min.PNG`}
										alt=""
									/>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
			<div className={css.line} />
		</div>
	);
};

export default Revievs;
