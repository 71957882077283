import React, { useEffect, useState } from "react";
import css from "../styles/Card.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";

const SignalStory = ({ signals }) => {
	const { t, i18n } = useTranslation();

	return (
		<div className={css.signalsStoryContainer}>
			<div
				className={classNames(
					css.signalsStory,
					i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium",
				)}
			>
				<div className={css.title}>{t("signalStory.title")}</div>
				{!signals.length && <div className={css.text}>{t("signalStory.noSignals")}</div>}
				{!!signals.length &&
					signals
						.sort((a, b) => b.created - a.created)
						.map((item, key) => {
							return (
								<div
									className={css.signal}
									key={`${item.coefficient}-${item.created}-${key}`}
								>
									<div className={css.coefficient}>X{item.coefficient}</div>
									<div className={css.date}>
										{moment(item.created * 1000).format("hh:mm DD.MM.YYYY")}
									</div>
								</div>
							);
						})}
			</div>
		</div>
	);
};

export default SignalStory;
