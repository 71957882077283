/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import css from "../styles/Card.module.scss";
import styles from "../styles/App.module.scss";
import classNames from "classnames";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Vining from "./Vining";
import Card3 from "./Card3";
import AmazingLoader from "./AmazingLoader";
import SignalStory from "./SignalStory";

const coefficients = [1, 2, 3, 3, 2, 2];

const Card = ({ lang, data, setPopup, setVideo }) => {
	const { t, i18n } = useTranslation();
	const [animeProgressWidth, setAnimeProgressWidth] = useState(0);
	const [animeProgressWidthDec, setAnimeProgressWidthDec] = useState(0);

	const [userData, setUserData] = useState({});
	const [signalData, setSignalData] = useState({});

	const [signals, setSignals] = useState([]);

	const [step, setStep] = useState(0);

	const animationProgress = fullNum => {
		const split = String(Number(fullNum).toFixed(2)).split(".");

		let num = 0;
		let num2 = 0;

		const interval0 = setInterval(() => {
			if (num >= +split[0]) {
				clearInterval(interval0);
			} else {
				setAnimeProgressWidth(num + 1);
				num += 1;
			}
		}, 2000 / +split[0]);

		const timeout0 = setTimeout(() => {
			const interval1 = setInterval(() => {
				if (num2 >= +split[1]) {
					clearInterval(interval1);
				} else {
					setAnimeProgressWidthDec(num2 + 1);
					num2 += 1;
				}
			}, 1000 / +split[1]);
		}, 2000);
	};

	useEffect(() => {
		getData();
	}, []);

	const getSignals = async lead_id => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_HOST}/api/AviatorSoft/Client/getSignalHistorySuccess?lead_id=${lead_id}`,
			);
			if (!!response.data.success) {
				setSignals(response.data.data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getData = async () => {
		try {
			const response = await axios.get(
				`${
					process.env.REACT_APP_HOST
				}/api/AviatorSoft/Client/getData?platform_id=${+data.platform_id}`,
			);
			if (!!response.data.success) {
				setUserData({ ...response.data.data });
				animationProgress(String(response.data.data.percent));
				localStorage.setItem("aviatorSoft", JSON.stringify(response?.data?.data));
				getSignals(response.data.data.lead_id);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const getSignal = async () => {
		try {
			setStep(1);
			const response = await axios.get(
				`${process.env.REACT_APP_HOST}/api/AviatorSoft/Client/getSignal?lead_id=${userData?.lead_id}`,
			);

			const { data } = response?.data;
			setSignalData(data);

			const timeout_id = setTimeout(() => {
				setStep(2);
				clearTimeout(timeout_id);
			}, 5000);
		} catch (error) {
			const timeout_id = setTimeout(() => {
				setStep(0);
				popupLimitSignal();
				clearTimeout(timeout_id);
			}, 5000);

			console.log("Error", error);
		}
	};

	const popupLimitSignal = () => {
		setPopup({
			close: true,
			active: true,
			text: <>{t("limitSignal")}</>,
			title: <>{t("popupTitle")}</>,
			actions: (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						gap: "10px",
						width: "100%",
					}}
				>
					<button
						className={classNames(
							styles.button,
							styles._small,
							i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
						)}
						onClick={() =>
							window.open(`https://thlbots.com/?type=deposit&lead_id=${userData.lead_id}`, "_blank")
						}
					>
						{t("popupButtons.b1")}
					</button>
					{!!data.username && (
						<button
							className={classNames(
								styles.button,
								styles._small,
								styles._blue,
								i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
								styles._gray,
							)}
							onClick={() => window.open(`https://t.me/${data.username}`, "_blank")}
						>
							{t("popupButtons.b2")}
						</button>
					)}
				</div>
			),
			subActions: (
				<>
					<div
						className={styles.videoBoard}
						onClick={() => setVideo({ show: true, video: "Tutor_Deposit.mp4" })}
					>
						<span
							className={classNames(
								i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
								styles.text,
							)}
						>
							{t("makeDeposit")}
						</span>
						<div className={styles.buttonPlay}>
							<div className={styles.icon} />
						</div>
					</div>
				</>
			),
		});
	};

	const setMessagePopup = () => {
		setPopup({
			close: true,
			active: true,
			text: (
				<>
					{t("popupText.span1")} <span className={styles.spanRed}>{userData.platform_id}</span>{" "}
					{t("popupText.span2")} <span className={styles.spanGreen}>100%</span>{" "}
					{t("popupText.span3")}{" "}
					<span className={styles.spanRed}>{Number(userData.percent).toFixed(2)}%</span>{" "}
					{t("popupText.span4")}
				</>
			),

			title: <>{t("popupTitle")}</>,
			actions: (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						gap: "10px",
						width: "100%",
					}}
				>
					<button
						className={classNames(
							styles.button,
							styles._small,
							i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
						)}
						onClick={() =>
							window.open(`https://thlbots.com/?type=deposit&lead_id=${userData.lead_id}`, "_blank")
						}
					>
						{t("popupButtons.b1")}
					</button>
					{!!data.username && (
						<button
							className={classNames(
								styles.button,
								styles._small,
								styles._blue,
								i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
								styles._gray,
							)}
							onClick={() => window.open(`https://t.me/${data.username}`, "_blank")}
						>
							{t("popupButtons.b2")}
						</button>
					)}
				</div>
			),
			subActions: (
				<>
					<div
						className={styles.videoBoard}
						onClick={() => setVideo({ show: true, video: "Tutor_Deposit.mp4" })}
					>
						<span
							className={classNames(
								i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
								styles.text,
							)}
						>
							{t("makeDeposit")}
						</span>
						<div className={styles.buttonPlay}>
							<div className={styles.icon} />
						</div>
					</div>
				</>
			),
		});
	};

	return (
		<>
			<h2
				className={classNames(i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium", css.title)}
			>
				{t("cardTitle")}
			</h2>

			<div className={classNames("enBold", css.coefficients)}>
				{coefficients.map((el, index) => {
					return (
						<React.Fragment key={`${el}-${index}`}>
							<div
								className={classNames(
									"enBold",
									css.coef,
									+el >= 1.0 && +el < 2 && css._1,
									+el >= 2.0 && +el < 3 && css._2,
									+el >= 3.0 && css._3,
								)}
							>
								X<div className={css.loader}></div>
							</div>
						</React.Fragment>
					);
				})}
			</div>
			<Vining />
			{step === 1 && <AmazingLoader />}
			{step === 2 && (
				<Card3
					setPopup={setPopup}
					signalData={signalData}
					data={data}
					signals={signals}
					setVideo={setVideo}
					setStep={setStep}
					getData={getData}
				/>
			)}
			{step !== 2 && step !== 1 && (
				<div className={css.card}>
					<div className={css.cardBody}>
						<div className={css.loaderContainer}>
							<svg
								className={css.loader}
								width="130"
								height="130"
								xmlns="http://www.w3.org/2000/svg"
							>
								<image
									className="load"
									href="load.svg"
									height="130"
									width="130"
									color="black"
								/>
							</svg>
						</div>
						<div className={css.info}>
							<p
								className={classNames(
									i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium",
									css.text,
								)}
							>
								{t("cardDescription")}
							</p>
							<div style={{ width: "100%", display: "flex", gap: "10px" }}>
								<button
									className={classNames("enBold", styles.button, styles._small)}
									onClick={getSignal}
								>
									{t("cardButton")}
								</button>
								<button
									className={classNames(
										styles.button,
										styles._small,
										styles._blue,
										i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
									)}
									style={{ backgroundColor: "#FFD700", color: "black" }}
									onClick={() =>
										window.open(
											`https://thlbots.com/?type=play&lead_id=${userData.lead_id}`,
											"_blank",
										)
									}
								>
									{t("buttonPlay")}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className={classNames(css.progressWrapper, "enBold")}>
				<div className={classNames(css.progress2, css.progress_moved)}>
					<div
						className={css.progress_bar2}
						style={{
							width: `${animeProgressWidth}.${animeProgressWidthDec}%`,
						}}
					>
						<span className={css.lightning}></span>
					</div>
					<div className={classNames(css.enBold, css.interest, "enBold")}>
						{`${animeProgressWidth}.${animeProgressWidthDec}`}%
					</div>
				</div>
			</div>
			<div className={classNames(css.warning)}>
				<p>
					{t("description.span1")}{" "}
					<span
						className={classNames(
							css.spanRed,
							i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
						)}
					>
						{userData?.platform_id}
					</span>{" "}
					{t("description.span2")}{" "}
					<span
						className={classNames(
							css.spanGreen,
							i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
						)}
					>
						{Number(userData?.percent).toFixed(2)}%
					</span>{" "}
					{t("description.span3")}
				</p>
			</div>
			<button
				className={classNames(styles.showVideo)}
				onClick={setMessagePopup}
			>
				{t("cardButton2")}
			</button>
			<SignalStory signals={signals} />
			{!!data.username && (
				<button
					className={classNames(
						styles.button,
						styles._small,
						styles._blue,
						i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
						styles._gray,
					)}
					onClick={() => window.open(`https://t.me/${data.username}`, "_blank")}
				>
					{t("card3_popup3.buttons.b2")}
				</button>
			)}
		</>
	);
};

export default Card;
